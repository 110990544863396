<template>
  <div id="v-pills-workexperience" role="tabpanel" aria-labelledby="v-pills-workexperience-tab">
    <div class="col content">
      <h5 class="text-primary p-3">Work experience<span class="float-right"></span></h5> 
      <hr/>
        <div id="a" class="p-4">
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Total experience<span style="color: red">*</span></label></div>
            <div class="col-lg-8"><p>{{user_exp}}</p></div>
          </div>
        </div>
      <hr/>
      <h5 class="text-primary p-3">Experience<span class="float-right" style="font-size: 14px;"></span></h5><hr>
      <div class="p-4" id="c" v-if="current_company">
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Company Name<span style="color: red">*</span></label></div>
          <div class="col-lg-8" v-if="current_company.worked_company_name"><p>{{current_company.worked_company_name}}</p></div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Designation<span style="color: red">*</span></label></div>
          <div class="col-lg-8" v-if="current_company.designation"><p>{{current_company.designation}}</p></div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Working since(From)<span style="color: red">*</span></label></div>
          <div class="col-lg-8" v-if="current_company.work_duration_month_from && current_company.work_duration_year_from"><p>{{getMonth(current_company.work_duration_month_from)}} - {{current_company.work_duration_year_from}} to Present</p></div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Skills<span style="color: red">*</span></label></div>
          <div class="col-lg-8" v-if="current_company.skills"><p>{{current_company.skills}}</p></div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Current Salary Per Month</label></div>
          <div class="col-lg-8" v-if="current_company.current_salary"><p>{{current_company.current_salary}}</p></div>
          <div class="col-lg-8" v-if="!current_company.current_salary"><p>-</p></div>
        </div>
      </div>
      <hr v-if="current_company">
      <div class="previous_companies" v-if="prev_companies.length">
        <div class="p-4" id="c" v-for="(company, index) in prev_companies" :key="index">
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Company Name<span style="color: red">*</span></label></div>
            <div class="col-lg-8" v-if="company.worked_company_name"><p>{{company.worked_company_name}}</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Designation<span style="color: red">*</span></label></div>
            <div class="col-lg-8" v-if="company.designation"><p>{{company.designation}}</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Working since(From)<span style="color: red">*</span></label></div>
            <div class="col-lg-8" v-if="company.work_duration_month_from && company.work_duration_year_from"><p>{{getMonth(company.work_duration_month_from)}} - {{company.work_duration_year_from}} to {{getMonth(company.work_duration_month_to)}} - {{company.work_duration_year_to}}</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Skills<span style="color: red">*</span></label></div>
            <div class="col-lg-8" v-if="company.skills"><p>{{company.skills}}</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Current Salary Per Month</label></div>
            <div class="col-lg-8" v-if="company.current_salary"><p>{{company.current_salary}}</p></div>
          </div>
          <hr v-if="prev_companies.length != index+1">
        </div>
      </div>
      <div v-else style="color: rgb(114, 114, 114);" class="text-center">No company added</div>
      <hr>
      <h5 class="text-primary p-3">Career Preference<span class="float-right" style="font-size: 14px;"></span></h5>           
      <hr>
        <div class="p-4" id="g">
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Job role<span style="color: red">*</span></label></div>
            <div class="col-lg-8" v-if="user_role.length"><p>{{user_role.join()}}</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Preferred Location<span style="color: red">*</span></label></div>
            <div class="col-lg-8"><p>{{user_details.user_city.city_name}}</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Expected Salary(Annual)</label></div>
            <div class="col-lg-8">
              <p v-if="this.user_career.preferred_salary">{{this.user_career.preferred_salary}}</p>
              <p v-if="!this.user_career.preferred_salary">-</p>
              </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Actions from '../../libraries/apiActions.js';
import '../../assets/hire.css';
export default {
    name:'ExperienceDetails',
    data(){
      return {
        action: new Actions(),
        user_details:this.$attrs.user.personal_details,
        user_career : this.$attrs.user.careerPreferencesDetail,
        current_company : this.$attrs.user.current_company,
        prev_companies : this.$attrs.user.previous_companies,
        user_exp: null,
        designations:[],
        skills:[],
        user_role:[],
        cities:this.$store.state.states.locations.locations,
        jobrole:this.$store.state.jobrole.jobrole.jobrole,
        exp: {
          options: [
            { value: 1, text: "0 Month(Fresher)" },
            { value: 6, text: "06 Months" },
            { value: 12, text: "1 Year" },
            { value: 24, text: "2 year" },
            { value: 36, text: "3 year" },
            { value: 48, text: "4 year" },
            { value: 60, text: "5 year" },
            { value: 72, text: "6 year" },
            { value: 84, text: "6+ year" },
          ],
        },
      }
    },
    beforeMount(){
      if(this.user_details.total_work_experience == 0){
        this.user_details.total_work_experience = 1;
      }
      this.getExp(this.user_details.total_work_experience);
      this.getDesignations();
      this.getSkills();
      this.setJobRole();
      // this.setCareerDetails();
      this.setExperienceDetails();
    },
    methods: {
      getExp(id){
        this.exp.options.forEach(e => {
          if(e.value == id){
            this.user_exp = e.text;
          }
        })
      },
      getMonth(data){
        var mm = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
        return mm[data+1]
      },
      // setCareerDetails(){
      //   if(this.user_career.prefered_city == null){
      //     this.toEdit.career_preference.preferred_location = this.user_details?.user_city.city_id;
      //   }else{
      //     this.toEdit.career_preference.preferred_location = this.user_career.prefered_city; 
      //   }
      // },
      setExperienceDetails(){},
      getDesignations(){
        this.action.getDesignations().then(res => {
          res.designations.forEach(desig => {
            this.designations.push({value:desig.designation_id, text:desig.designation_name})
          })
        })
      },
      getSkills(){
        this.action.getSkills().then(res => {
          res.skills.forEach(skill => {
            this.skills.push({value:skill.id, text:skill.skill});
          })
        })
      },
      setJobRole: function(){
        this.$store.state.jobrole.jobrole.jobrole.forEach((element) => {
          if(element.value == this.user_career.fst_prefered_role){
            this.user_role.push(element.text)
          }
          if(element.value == this.user_career.snd_prefered_role){
            this.user_role.push(element.text)
          }
          if(element.value == this.user_career.trd_prefered_role){
            this.user_role.push(element.text)
          }
        })
        // if(this.user_career.fst_prefered_role){
        //   this.toEdit.career_preference.jobrole.push(this.user_career.fst_prefered_role);
        // }
        // if(this.user_career.snd_prefered_role){
        //   this.toEdit.career_preference.jobrole.push(this.user_career.snd_prefered_role);
        // }
        // if(this.user_career.trd_prefered_role){
        //   this.toEdit.career_preference.jobrole.push(this.user_career.trd_prefered_role);
        // }
      },
      checkWorkingStatus(e){
        this.toEdit.company.employer_status = (e.target.checked)?1:0;
      },
    }
}
</script>

<style>
.edit-button{
  position: absolute;
  right: 15px;
  top: 25px;
  cursor: pointer;
}
.edit-button img{
  width: 25px;
}
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
  min-height:55px !important;
}
.v-text-field__details{
  display: none !important;
}
.v-input__slot{
  margin-bottom:0 !important;
}
</style>
<style scoped>
input{
  padding: 28px 0px 28px 15px !important;
}
.error-feedback{
  position: absolute;
}
</style>